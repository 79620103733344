<template>
  <v-container fluid>
    <v-row class="mt-2 px-12" v-if="dataLoading">
      <v-col cols="12">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i + 12">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="mt-8">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-form @submit.prevent ref="form">
        <v-card-title class="primary--text">
          New Project
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-card flat outlined class="pa-4 pa-sm-6">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Project Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Project Title *"
                  v-model="project.name"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Project Code *"
                  v-model="project.code"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="project.coordinator_uuid"
                  :items="coordinators"
                  item-text="name"
                  item-value="uuid"
                  label="Coordinators"
                  outlined
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-textarea
                  flat
                  outlined
                  label="Description"
                  v-model="project.description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
            cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="createProject"
            :loading="loading"
          >
            Create
          </v-btn>
        </v-card-title>
      </v-form>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";

import { addProject } from "@/services/projectsService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    project: {
      uuid: "",
      code: "",
      name: "",
      description: "",
      coordinator_uuid: "",
      staff: {},
    },
    coordinators: [],
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Projects", "Create")
    ) {
      this.dataLoading = true;
      this.coordinators = await fetchAndStoreStaffData();
      this.coordinators.forEach((coordinator) => {
        coordinator["name"] =
          coordinator.first_name + " " + coordinator.last_name;
      });
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageProjectList" });
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewProject",
        params: { uuid: item.uuid },
        query: { bc: item.name },
      });
    },
    async createProject() {
      if (this.$refs.form.validate()) {
        this.project.uuid = uuidv4();
        this.project.staff = this.coordinators.find(
          (coordinator) => coordinator.uuid == this.project.coordinator_uuid
        );
        this.loading = true;
        let addProjectReturn = await addProject(this.project);
        this.loading = false;
        if (addProjectReturn == "success") {
          this.viewItem(this.project);
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
  },
  updated() {
    //
  },
};
</script>